import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const CountdownWedding = styled.div`
    background-color: ${p => p?.showWarning ? '#FBEAEA' : '#F3F1F1'};
    color: #5A5A5A;
    font-size: 14px;
    padding: 4px 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    i {
        padding-right: 8px;
    }

    @media ${device.mobileL} {
        white-space: normal;
        line-height: 16px;

        i {
            padding-right: 4px;
        }
    }
`;

export const RoomPlanContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: auto;
    margin-bottom: ${p => p?.guestMap ? '130px' : 'auto'};
    padding: 0 25px;

    @media ${device.mobileL} {
        padding: 0 15px;

       .col-terms {
            text-align: left !important;
        }
    }

    .col-chefe {
        text-align: left;
    }

    .col-terms {
        text-align: end;
    }

    .col-chefe i,
    .col-terms i {
        margin-right: 5px;
    }
`;

export const Toolbar = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .space {
        border-left: 1px solid ${({ theme }) => theme.inputBorderColor};
        margin: 0 5px;
        height: 32px;
    }

    div.toolbar-input {
        border-right: 1px solid #DFE0DF;
        margin-right: 8px;
        padding-right: 8px;
    }
    
    div.toolbar-input:last-child {
        border-right: none;
        margin-right: 0;
        padding-right: 0;
    }

    .zoom-icons .anticon {
        font-size: 18px;
        padding: 5px;
    }

    ${({ right }) => right &&
        `float: right;`
    }
`;

export const RoomEditor = styled.div`
    height: ${p => p?.guestMap ? '200px' : 'calc(100vh - 230px)'};
`;

export const RoomEditorContent = styled.div`
    border: ${p => p?.noBorder ? 'unset' : '1px solid #EBEBEB'};
    border-radius: ${p => p?.noBorder ? 'unset' : '8px'};
    text-align: left;
    width: 100%;
    height: 100%;
    max-height: 300px;

    .title, 
    .text {
        padding: 0 4px;
        background-color: #F5F5F5;
        width: 100%;
        display: grid;
    }

    .title {
        font-weight: 600;
        margin-bottom: 0;
    }

    .text {
        line-height: 15px;
        padding-bottom: 4px;
    }

    .info-content {
        padding: 0 4px 4px 4px;
        overflow: auto;
        height: calc(100% - 44px);
    }

    .tables-search-content {
        padding: 0 4px 4px 4px;
        overflow: auto;
        height: calc(100% - 44px);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    .ant-tabs-bar {
        margin-bottom: 0;
    }

    .ant-tabs .ant-tabs-top-content {
        padding: 4px;
        border: 1px solid #EBEBEB;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`;

export const TableSearchCode = styled.div`
    width: 26px;
    height: 24px;
    border: 1px solid #B1B1B1;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-inline-start: 4px;
    margin-inline-end: 4px;
    margin-block-start: 4px;
    margin-block-end: 4px;
    background-color: ${p => !p?.active ? '#d9d9d9' : 'inherit'};

    &:hover {
        background-color: ${p => p?.theme?.primaryColor};
        color: white;
        cursor: pointer;
    }
`;

export const RoomEditorContainer = styled.div`
  position: relative;
  margin-bottom: ${p => p?.guestMap ? '0px' : '30px'};
  background-color: ${p => p?.guestMap ? `white` : 'unset'}
  ${p => p?.guestMap && `
  height: calc(100% - 46px);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  `}

  .backdrop {
    z-index: 999;
    background-color: black;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.4;
    border-radius: 5px;
  }

  .ant-spin {
    position: absolute;
    height: 30px;
    display: flex;
    margin: 0 !important;
    top: 45%;
  }
`;

export const RoomPlanBg = styled.div`
    width: 100%;
    height: calc(100vh - 250px);
    position: relative;
    display: flex;
    justify-content: center;
    position: relative;

    img {
        height: 100% !important;
        width: auto;
    }

    div {
        position: absolute;
        top: 45%;
        font-size: 24px;
        font-family: titillium web 
        font-weight: 600;
    }
`;

// Form Tables + seats
export const TableGuests = styled.div`
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    background-color: ${({ couple, staff, theme, disabled }) => disabled ? theme?.disabledTableColor : couple ? theme.coupleTableColor : staff ? theme?.staffTableColor : theme?.tableColor};
    border-radius: 8px;
    width: 100%;
    height: 100%;
    box-shadow:0 2px 5px #ccc;
`;

export const TableGuestsModal = styled.div`
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    border-radius: 8px;
    width: 45%;
    box-shadow:0 2px 5px #ccc;
`;

export const TableGuestsType = styled.div`
    display: flex;
    flex-direction: row;
    height: 30px;
    padding: 4px;
    justify-content: space-between;

    .guest-table-content {
        display: flex;
        flex-direction: row;
    }

    .guest-table {
        min-width: 25px;
        width: auto;
        background-repeat: no-repeat;
        height: 30px;
    }

    i {
        font-size: 20px;
        padding-right: 5px;
    }

    .cake-icon {
        background-image: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAARABADAREAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD+5z4h/Fjwt8Pta0vSvEHjHw/4bl1Hwl428RQ2msWMlzPdJ4ZTRmW7gul17So4Utm1CUf2O9pPd+JHYw6ZqOly6Zci8AD4e/Fjwr8QNc1XSPD/AIz8O+JJtO8KeDPET2ej2MlrcQQ+JI9WZ9QkuX13VEmguns4Y/7HFnbX3hmWPydVvtSfVbM2wB8ZeNJL0/Gj4zeJINP8UrLPr2g6Ho1p4z/Y4+Lvxe8F6xpejeBNH0m81TRvEPhb4errsS6X4sj1R9PXTfF0nhPWIlvb21024XWo9eIBN4F87TPjZ8HZbOw8W+I9Hsr3xVZ6h4tl/Y5+K3wd17T5PEfhJtNisvE2vRfD3w54Nl8KTeIIoLi0lfTtNOlK9idevrpdHfXbwA/SOgAoAP/Z');
    }
    
    .guest-round-table {
        background-image: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAUABIDAREAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD+vbWvi/8AE34/+F2+HPg74c6DHcfEfw14f8ZWuqW/xIvjpnhb4dT+K1tNY0L4xTW/hCx1Lwj4o8S2eja34U/4RnwkPGV99tHimylubNPCt5f3YBseFr34mfs6Xdx4Nj+Engo6J8T/AIhJP8O7Twh49uNC+EXgCVvBemrq3hbUbrVfB41fwc+v6n4X1vxLoFhovhHUvDOp6/4gutNN/pOrXsQ1IA9f8N/tL+DfEHh3QNebQvGdo2t6LpWrta23h671e3tm1KxgvDbwatp6yWGqQwmby4tRsXezvUVbm2doZUJAPmn4K/HLwJ4Y+J/xE8U+Nr/wF4MtfjRaeGPFOp/2Ggtrf4T+K9Hm1bw7P8P/AI7a1572fhjx3qds9hfQ6p4ii8N2OseJU8XaHbwm60ywu9fAOt/aZ+MXwl+InhLT/hhoPjz4davba5408E3vizxnqbweK/hT4A0nwl4gtPHo/wCFhazp93F4bjl8Uy+FE8K6Z4Q1DxJo+q+IhrM4tmjtILmdQD17wZ8YvGWo+DvCeoJ8DPFFsl/4a0K8S20drS00m3W60u1nWDS7TUIrC/tdOiDiOyt76wsryG2WKO5tLaZXhQA7P4+6RZ6l8HfiIZg0b6doTeK7eWARK7av4KntvFehvP5kUqTwJquh2AuYJUZZ7RZbbKB9ygHj/wCxD4mu/iP8GW+MWs2emad4l+LfiW98T+IdO0G1bT/DmnXWi6Vo3gLT7Hw/p0s13c2enR6L4Q02aRL2/wBSvJtRuL+5kvWimgt7YA+w6AD/2Q==');
    }
    
    .guest-oval-table {
        background-image: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAUABcDAREAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD+vy68O+NvG/xl1L4v/CvwbpXig+G/iUkWnePPHHii68BW994U8P8AgC8+Hfin4Y+B7nT9B8c6xrHgqTxu2q+L77WLvRdC8N6r4jXzdJs9dbT7PxIgBP4d+GH7Qnh/4my/FKL4a/CCTxDqPiHx3qHiK+j+Mvie31TxF4c8S2+nweF/CWqamPgo8t1pXgUabbto0NzHPZwyG4n0+y0ue9vXuAA+Eega38HvijY6n8VtBsPBWrfEDSPG+mat4z0PxCvibwb8RfFl94pu/iDop8WeJr+38M6xp3ivwp4Xh8RaN4dXX/Cr6dd6ELnT9E8R262Vt4eAB3fwo+KngT4L/D/wv8Jfi94k0f4aeJ/htpdh4FWXxnfQaDpXjTT/AA/bR6fo3jHwvr2orY6X4kg8T6XBa6tqK2E0mo6XrVxqWl6xa219aS+YAeiD9qH9m8ttHx3+EpYsyhR4/wDDJYsgBdcf2lncgILDqoIJxQB4v8cNZ8OftV+DW+C3wc8SWPidfEOoWOoeJviJ4feTU/B/gTSdAeTWreWXxLYrJpd/r+t6zp+neHrXw9pV5darHZajqeqX0FrY2DNOAcf8Rdbu7P8Abn+GPwxiWBvDvxF0zS/irrs0iOdUt/Efws0fxXp3h620y5WRLeDSLuKSObWLa5s7y7uJ7WA2d9p8LXMNwAP+HGr3Vx+3J8SPhW4iHhn4ceH/ABD8XfD8ioRqkvij4st4Ts/EttqV0XMNzo1qLi9uNGtYbW1u7ae/n+2X+oQRWUFoAQ/sZaxe698UP2gtBvzGbL4B60nwT8ENDH5dxceEh4o8WeIGu9ffLJf69JcxWls15aR6fZCzs4Vj01LmS7urkAD/2Q==');
    }
    
    .guest-square-table {
        background-image: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAUABYDAREAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD+0v8AaMvtS8W6j8PfhF4Y8P6B43ufEOu3Pi3xZ4b1zxWug6JqXhL4d3mgXeqeG9fSDSNfe9t9cvte0gNpd9pz6beQWV1FqEdxbsbaUA+YL74G/HXRtM1jWtS+HPgC5/4QfShc/CG4/wCFtatNP8IbLRvi78SviSw8JpJ4BItpz4B1/wAD/DPfaNZyz6L4CtrOaV9HvjpUIB+kngzxZpfjfwxoPifSpI/s+uaLpGs/ZBc29zcaeNX0+31COzvDbSSIl1Ak4jlXIG9G28UAfB+j+CtE/Zc+KfwevPG2rfDTT/Ccfgv4meEbTx9pPgC88K+LfEesXFx4BudOPxL8Qx6xrsHifxZrcVnqF898sOnPq+rQ65qsdpAbt7O1APcvGP7UX7Pt94P8ZWln8VfC1zc/8I3rlqYIZ7l5EuLiDVNJghkUWv7uSXVLO801Fk2lr60urQfv7eZEAMX9mn4Iar4Kt/BnjnWbXwD4bvR8I9J8KHw18P8A4f3Xge4vW1NPC+r3GpfES5utc1B/EnibRpdEFhpkjaXpTaS+q+KpCZjrbW9iAdn+1Ze3GgfBjXfHWnOI9d+Gup+HfHvh1pEWW0bWdD1m0SGDUbdh/pOnXdteXdpeQxyW9wYZ2ktLu0uo4bmIA+CtVvb7Qtd/Zh8N2l5JLafth6fpk3xcurmK1e9jlX4q+JPjpJJ4TaK3htdFM/iz4xeNdMlW5tNURfDdxp9pAkOqafFrDAH7BUAA/9k=');
    }
    
    .guest-rectangular-table {
        background-image: url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAgEASABIAAD/2wBDAAEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/2wBDAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQH/wAARCAAUABcDAREAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD+trwl4f8Ajj8TfEfgv9pvR/hp8MIbzxZe+DPiN4Um1T4yeJYvEOk/CzW/g9BojfC6e6g+Dt1BbaZqeuaxc+PNUtLYz6V/wkEke+zvL2ys9bhAOq8PfDH9oXQPidL8Uk+G3whl8Raj4g8dX3iG/T4zeJ4NV8ReG/Eltp1t4W8I6pqf/ClJJbnSfAa6ZbHRYLiOezgfz5tPstLmvb15wDzPUJvi1+yZaa/8fPFvwz8AanYtpetab8SZPDPxc1+fxD421zxl8TLPVPCXiHU01L4VWVtqd34Qt9XvPC1k9/eNcWmhXjQ6dPbafa2+jKAez/s7/tF/APw/+z/8C9A1z4z/AAx0fXNF+D3wv0fWNI1Lxt4estS0vVrHwTolpe6bf2Vxfx3FpfWt1BNb3FrPGk0U0UkboHRgAD2MftQ/s3lto+O/wlLFmUKPH/hksWQAuuP7SzuQEFh1UEE4oA+Sf24fjn8GfH37NXjrwt4H+KngDxf4k1K78IS6foPhvxXoutavexWXjLQ7y8ktdP0+8uLqeO1tba4uLh44mWKGCWRyqoxABvfEXW7uz/bn+GPwxiWBvDvxF0zS/irrs0iOdUt/Efws0fxXp3h620y5WRLeDSLuKSObWLa5s7y7uJ7WA2d9p8LXMNwAP+HGr3Vx+3J8SPhW4iHhn4ceH/EPxd8PyKhGqS+KPiy3hOz8S22pXRcw3OjWouL240a1htbW7tp7+f7Zf6hBFZQWgBD+xlrF7r3xQ/aC0G/MZsvgHrSfBPwQ0Mfl3Fx4SHijxZ4ga7198sl/r0lzFaWzXlpHp9kLOzhWPTUuZLu6uQD/2Q==');
    }
`;

export const TableGuestsHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${p => p?.modal ? '30px' : '40px'};
    padding: 4px;
`;

export const TableCategory = styled.div`
    padding: 4px 4px 0 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    
    span {
        font-size: 20px;
        font-weight: 600;
        margin-left: 8px;
        color: ${p => p?.staff ? p?.theme?.staffColor : p?.theme?.goldColor};
    }

    .category-icon {
        height: 26px;
        width: 25px;
    }

    svg, svg path {
        color: ${p => p?.staff ? p?.theme?.staffColor : p?.theme?.goldColor};
        fill: ${p => p?.staff ? p?.theme?.staffColor : p?.theme?.goldColor};
        fill-opacity: 1;
        height: 25px;
        width: 25px;
      }
}
`;

export const TableName = styled.div`
    padding: 0 4px;
    width: 100%;
    color: ${({ couple, staff, theme }) => couple ? theme.goldColor : staff ? theme?.staffColor : 'inherit'};
`;

export const TablePurpose = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    background-color: white;
    height: auto;
    color: rgba(0, 0, 0, 0.65) !important;
    padding: 4px;
    opacity: ${p => p?.disabled ? 0.4 : 1};
`;

export const SeatRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: white;
    height: ${p => p?.modal ? 'auto' : '40px'};
    color: rgba(0, 0, 0, 0.65) !important;
    padding: 4px;
    opacity: ${p => p?.disabled ? 0.4 : 1};
    border: ${({ hasError }) => hasError ? '1px solid red' : '1px solid transparent'};
`;

export const TableSeatCode = styled.div`
    background-color: ${({ seat, couple, theme }) => seat && couple ? theme.goldColor : !seat && couple ? '#F6EBD0' : seat && !couple ? '#F5F5F5' : '#EBEBEB'};
    color: ${({ seat, couple, theme }) => seat && couple ? 'white' : !seat && couple ? theme.goldColor : seat && !couple ? '#5A5A5A' : 'inherit'};
    border-radius: 2px;
    margin: ${({ seat, modal }) => seat && !modal ? '0 0 6px 0' : '0'};
    width: 22px;
    font-size:  ${({ seat }) => seat ? 'inherit' : '16px'};
    font-weight:  ${({ seat }) => seat ? 'inherit' : '600'};
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const SeatName = styled.div`
    width: ${p => p?.modal ? 'calc(100% - 22px)' : 'calc(100% - 250px)'};
    padding: 0 5px;
    ${p => p?.modal && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `}

    @media ${device.mobileL} {
        width: ${p => p?.modal ? 'calc(100% - 22px)' : 'calc(100% - 164px)'};
    }
`;

export const SeatAge = styled.div`
    width: 100px;
    padding: 0 5px;
    display: ${p => p?.staff ? 'none' : 'block'};

    @media ${device.mobileL} {
        width: 60px;
    }
`;

export const SeatFoodRestrict = styled.div`
    width: ${p => p?.staff ? '201px' : '100px'};
    min-width: ${p => p?.staff ? '191px' : '90px'};
    padding: 0 5px;

    @media ${device.mobileL} {
        width: ${p => p?.staff ? '161px' : '60px'};
        min-width: ${p => p?.staff ? '161px' : '60px'};
    }
`;

export const DeleteSeatButton = styled.div`
  cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'};
  text-align: center;
  height: 40px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  opacity: ${p => p?.disabled ? 0.6 : 1};

  .anticon {
    opacity: ${p => p?.disabled ? 0.3 : 1}
  }
`;

export const TableGuestsOptions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10px;
    padding: 0 4px;
`;

export const TableGuestsStats = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
`;

export const StatsContent = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    opacity: ${p => p?.disabled ? 0.4 : 1};
`;

export const StatsContentRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 22px
    ${({ global }) => global && `background-color: white;`}

    div.stats-description {
        font-size: 15px;
        padding-left: 4px
    }

    div.stats-value {
        margin: 2px 0;
        background-color: white;
        height: 20px;
        width: 20px;
        text-align: center;
        border-radius: 4px;
    }
`;

export const StatsTotal = styled.div`
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    div.pax {
        font-size: 16px;
        font-weight: bold;
        width: 35px;
        height: 44px;
        background-color: ${({ couple, theme }) => couple ? '#F6EBD0' : '#EBEBEB'};
        color: ${({ couple, theme }) => couple ? theme.goldColor : 'inherit'};
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    div.stroller {
        font-size: 14px;
    }

`;

export const BtnAddTable = styled.div`
    width: 100%;
    height: 100%;
    min-height: 150px;
    background-color: #F5F5F5;
    border: 1px dashed #999999;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const GuestMapStats = styled.div`
    text-align: left;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    width: 100%;
    
    .title, 
    .text {
        padding: 0 4px;
        background-color: #F5F5F5;
    }

    .title {
        font-weight: 600;
        margin-bottom: 0;
    }

    .text {
        line-height: 15px;
        padding-bottom: 4px;
    }

    .stats-value {
        width: auto !important;
        padding: 0 4px;
        font-weight: 600;
        color: #000000;
    }
    
    .avg-value {
        color: ${({ avgError, theme }) => avgError ? theme.inputErrorColor : '#000000'};
    }

    .total-pax {
        font-weight: 700;
        font-size: 17px;
    }
`;

export const CostExtraMessage = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    text-align: left;
    justify-content: center;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    padding: 4px;

    div.content {
        display: flex;
        flex-direction: column;
        margin-top: 12px;
    }

    .title {
        font-weight: 700;
        font-size: 16px;
    }

`;

export const OrderContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

export const SeatOrderText = styled.div`
    padding: 2px 10px;
    margin-bottom: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    display: inline-flex;
    align-items: center;
    opacity: ${p => p?.disabled ? 0.2 : 1};
    background-color: ${p => p?.frontCouple ? '#F6EBD0' : 'inherit'};
`;

export const SeatOrderName = styled.div`
    width: ${p => p?.withAge ? 'calc(100% - 125px)' : '100%'};
    padding: 0 5px;
    opacity: ${p => p?.noGuest ? 0.6 : 1};
`;

export const SeatOrderAge = styled.div`
    width: 100px;
    padding: 0 5px;
    display: flex;
    flex-direction: row-reverse;
    text-align: right;
`;