import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, Field, FieldArray, initialize, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { DetailContainer, FormContainer } from "../decoration/PageDetailStyles";
import ProgressBar from "../../components/progressBar/ProgressBar";
import DecorationNavigation from "../../components/decorationNavigation/DecorationNavigation";
import SaveButton from "../../components/buttons/SaveButton";
import { updateQuestionAction } from '../../redux/Wedding/wedding.actions';
import {
  findPageByTag,
  findPageByID,
  findQuestionByID,
} from "../../infra/services/utils/PageInfo";
import {
  findNextChapter,
  findPrevChapter,
  findPrevQuestion,
} from "../../infra/services/utils/PageNavigation";
import GuestsProvisionalListForm from "./GuestsProvisionalListForm";
import {
  GetGuests,
  EditProvisoryInfo,
  GetOurGuestsEditor,
  GetTableTypes,
  GetFoodResctrictions,
  SaveRoomPlanEditor,
  CheckUpdateCoupleTable,
  GetCoupleTable,
  SubmitRoomPlan,
  NotifyChangeRoomPlan,
  CheckSubmitRoomPlan,
} from "../../infra/requests/GuestsRequests";
import { SpinLoading } from "../../styles/BasicStyles";
import { blockedSubmitGuestList, editSection, processClosed, roomPlanClosed } from "../../infra/services/utils/BlockAnswers";
import LeavePageBlocker from "../../components/pageBlocker/LeavePageBlocker";
import Alerts from "../../components/alert/Alert";
import { inGuestMapTest } from "../../infra/services/utils/Helpers";
import { GetDynamicPage, GetTranslations, GetTranslationsRoomPlan } from "../../infra/requests/BaseRequests";
import { Alert, Button, Col, Icon, Modal, Row, Tabs, Tag, Tooltip } from "antd";
import { RoomPlanContainer, StatsContentRow, GuestMapStats, RoomEditorContainer, RoomEditor, RoomEditorContent, TableGuestsModal, TableGuestsHeader, TableSeatCode, TableName, SeatRow, SeatName, BtnAddTable, CostExtraMessage, TableSearchCode } from "./RoomPlanStyles";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import NumberInput from "../../components/inputs/NumberInput";
import FormValidator from "../../infra/services/validations/FormValidator";
import GuestPolicyModal from "./GuestPolicyModal";
import SectionModal from "../decoration/SectionModal";
import { correctResponseValues } from "../../components/optionCard/OptionUtils";
import { TransformToFormData } from "../../infra/services/formdata/TransformToFormData";
import { AnswerQuestion } from "../../infra/requests/MyWeddingRequests";
import { bindActionCreators } from "redux";
import MaterialNumberInput from "../../components/inputs/MaterialNumberInput";
import { calculateExtraTables, canChangeSeats, checkExtraCostByTables, convertNumberToLetter, getSummaryFoodRestrictions } from "./utilRoomPlan";
import BaseButton from "../../components/buttons/BaseButton";
import GuestTablesComponent from "./GuestTablesComponent";
import ImportGuestModal from "./ImportGuestModal";
import CostExtraIcon from '../../assets/icons/extra_cost.jpg';
import { Link } from "react-scroll";
import ReactSVG from "react-svg";
import DietaryRestrictionsIcon from '../../assets/icons/dietary_restrictions.svg';
import FoodRestrictionsModal from "./FoodRestrictionsModal";
import { checkCoupleTableAnswer, removeNotExistingOptions } from "../../infra/services/utils/Options";
import { GuestAgeEnum, TablePurposeEnum } from "./utilGuest";
const { confirm } = Modal;
const { TabPane } = Tabs;

const sectionTag = "GUESTS";
const chapterId = "65b37c49b42ba97e4a0db4c9";
const returnURL = "/guests";

export const StatsEnum = {
  ADULT: 'ADULT',
  NEWBORN: 'NEWBORN',
  CHILD: 'CHILD',
  STAFF: 'STAFF',
  TABLES: 'TABLES',
  GUESTS: 'GUESTS',
}

class GuestsMapList extends Component {
  timeout = 0;
  roomEditor = null;

  state = {
    chapter: undefined,
    nextChapter: undefined,
    prevQuestion: undefined,

    ready: false,
    loading: true,
    blocked: false,

    searching: false,
    search: '',

    content: {},

    tables: [],
    ourGuest: {},
    objectTypes: [],
    foodRestrictions: [],
    answerSeatId: false,

    notes: '',
    kitchenNotes: '',
    notesActiveTab: '1',
    invalidKitchenNotes: false,

    dirtyForm: false,
    submittedForm: false,
    saving: false,
    addingTable: false,

    blockedSubmit: false,
    submiting: false,

    coupleTableQuestion: {},
    tableThemeQuestion: {},
    questionModal: null,
    showModal: false,
    savingModal: false,
    canEditSection: true,
    isProcessClosed: false,

    openPolicy: true,

    openImportModal: false,
    importingGuests: false,
    importModalTable: null
  };

  componentDidMount() {
    const { activeLanguage, history } = this.props;

    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document.body.style.overflowY = "auto";

    if (!inGuestMapTest()) return history.goBack();

    const newdiv = document.createElement('div');
    newdiv.id = 'editorHided';
    newdiv.style.display = 'none';
    document.body.appendChild(newdiv);
    this.roomEditor = new window.RoomEditor('editorHided', 'pt');

    this.getQuestionsInfo();
    this.getTranslations();
    this.getInformation();
    this.getCoupleTableQuestion();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps?.id) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.body.style.overflowY = "auto";

      this.getQuestionsInfo();
    }

    if (this.props?.activeLanguage?.code !== prevProps?.activeLanguage?.code) {
      if (this.roomEditor) this.roomEditor.translationSystem.setActiveLanguage(this.props?.activeLanguage?.code || 'pt');
    }
  }

  componentWillUnmount() {
    if (this.roomEditor) {
      this.roomEditor.destroy();
    }
  }

  getQuestionsInfo = () => {
    const { structureInfo, wedding } = this.props;

    this.setState({ ready: false });
    const { chapters } = findPageByTag(structureInfo, sectionTag);
    const chapter = findPageByID(chapters, chapterId);
    const nextChapter = findNextChapter(
      structureInfo,
      chapter.section,
      chapters,
      chapter._id
    );
    const prevChapter = findPrevChapter(
      structureInfo,
      chapter.section,
      chapters,
      chapter._id
    );

    this.setState({
      chapter,
      nextChapter,
      prevChapter,
      ready: true
    });
  }

  getTranslations = async () => {
    const { data } = await GetTranslationsRoomPlan();
    if (this.roomEditor) this.roomEditor.setTranslations(data);
  }

  getInformation = async () => {
    const { translate, dispatch } = this.props;
    const { structureInfo, wedding, weddingDate, activeLanguage } = this.props;

    this.setState({ loading: true });
    const dataRoomEditor = {
      roomPlan: {
        imageSrc: null,
        x: 0,
        y: 0,
        scale: 0
      },
      tables: [],
      foodRestrictions: []
    };

    const { data } = await GetOurGuestsEditor();
    const ourGuest = data?.ourGuest || {};
    let tables = data?.tables || [];
    if (tables?.length > 0) {
      tables = tables.map(m => ({ ...m, tableType: m?.tableClass }))
    }
    let answerSeatId = data?.answerSeatId;

    // Set data for room editor
    dataRoomEditor.roomPlan = {
      imageSrc: `${process.env.REACT_APP_IMAGES_URL}${data?.ourGuest?.bgRoomPlan?._id}`,
      x: data?.ourGuest?.roomPlanPosition?.x || 0,
      y: data?.ourGuest?.roomPlanPosition?.y || 0,
      scale: data?.ourGuest?.roomPlanPosition?.scale || 0,
      horizontalCenterOfRoom: data?.ourGuest?.roomPlanHorizontalCenterOfRoom || 0,
      constraintPoints: data?.ourGuest?.roomPlanConstraintPoints || [],
      zones: data?.ourGuest?.roomPlanZones || [],
      spaceBetweenTables: data?.spaceBetweenTables || 0
    };
    dataRoomEditor.tables = tables;

    this.setState({
      ourGuest: ourGuest,
      notes: data?.ourGuest?.notes || '',
      kitchenNotes: data?.ourGuest?.kitchenNotes || '',
      blocked: roomPlanClosed(weddingDate) && !ourGuest?.roomPlanActive,
      blockedSubmit: blockedSubmitGuestList(weddingDate),
      answerSeatId
    });

    // Table types
    let objectTypes = [];
    const resultObjects = await GetTableTypes();
    if (resultObjects?.success) {
      objectTypes = resultObjects?.data || []
      // const coupleTable = resultObjects?.data?.filter(f => f?.coupleTable);
      const guestsTable = resultObjects?.data?.filter(f => !f?.coupleTable);

      if (this.roomEditor) {
        // this.roomEditor.activeCoupleTables(coupleTable.map(m => m?.tableClass));
        this.roomEditor.activeGuestTables(guestsTable.map(m => m?.tableClass));
      }
    } else {
      return Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('ERROR_LOADING_DATA')
      });
    }

    // Food restrictions
    let foodRestrictions = [];
    const resultFoodRestrictions = await GetFoodResctrictions();
    if (resultFoodRestrictions?.success) {
      foodRestrictions = resultFoodRestrictions?.data || [];
      dataRoomEditor.foodRestrictions = foodRestrictions;
    } else {
      return Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('ERROR_LOADING_DATA')
      });
    }

    dispatch(initialize('tables_form', {
      foodRestrictions: foodRestrictions || [],
      tables: tables || [],
      staff: data?.ourGuest?.staff || '',
      notes: data?.ourGuest?.notes || '',
    }));

    if (this.roomEditor) this.roomEditor.deserializeEditor(dataRoomEditor);

    this.setState({
      loading: false,
      objectTypes,
      foodRestrictions,
      tables
    }, () => {
      setTimeout(() => {
        if (this.roomEditor) this.roomEditor.setMode("COUPLE");

        if (answerSeatId) {
          if (this.roomEditor) this.roomEditor.setGuestsMode('SEAT');
        } else {
          if (this.roomEditor) this.roomEditor.setGuestsMode('TABLE');
        }
      }, 1500);
    });
  };

  getCoupleTableQuestion = () => {
    const { translate, dispatch } = this.props;
    const { structureInfo, weddingDate, blockDate } = this.props;

    const tag = 'DECORATION';
    const questionId = '5e18723686fdaa6d7aeedd91';

    const { chapters } = findPageByTag(structureInfo, tag);
    const decorQuestions = chapters.map(m => m?.questions).flat();
    // const question = findQuestionByID(chapters, questionId);
    const coupleTableQuestion = decorQuestions.find(f => f?.tableForRoomPlan);
    const tableThemeQuestion = decorQuestions.find(f => f?.isTableTheme);

    if (!coupleTableQuestion
      || !tableThemeQuestion) {
      Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('ERROR_LOADING_DATA')
      });
    }

    const canEditSection = editSection(weddingDate, tag);
    // const isProcessClosed = processClosed(weddingDate);
    const isProcessClosed = processClosed(blockDate);

    // dispatch(initialize("section_form", {}));
    // if (question?.answer) {
    //   dispatch(
    //     initialize("section_form", {
    //       ...question?.answer,
    //       question_obs: question?.answer?.observations !== "",
    //     })
    //   );
    // }

    this.setState({
      coupleTableQuestion,
      tableThemeQuestion,
      canEditSection,
      isProcessClosed,
    });
  }

  /** Save guest map and room plan */
  handleNotesChange = (event) => {
    this.setState({ dirtyForm: true, notes: event?.target?.value });
  }

  handleKitchenNotesChange = (event) => {
    this.setState({ dirtyForm: true, kitchenNotes: event?.target?.value });
  }

  changeNotesActiveTab = (e) => {
    this.setState({ notesActiveTab: e });
  }

  confirmAfterSave = () => {
    const { history, translate } = this.props;
    const { nextChapter } = this.state;

    confirm({
      title: translate('ADVANCE_TO_ROOM_PLAN'),
      okText: translate('ADVANCE'),
      cancelText: translate('STAY_HERE'),
      onOk: () => {
        history.push(`${returnURL}/${nextChapter?._id}`);
      },
      onCancel: () => this.getInformation(),
    });
  }

  confirmNotifyChangeRoomPlan = () => {
    const { translate } = this.props;

    confirm({
      title: translate('REACHED_TABLE_LIMIT'),
      content: translate('REACHED_TABLE_LIMIT_CONTENT'),
      okText: translate('INCREASE_ROOM_PLAN'),
      cancelText: translate('CANCEL'),
      onOk: () => this.notifyChangeRoomPlan(),
      onCancel: () => this.confirmAfterSave()
    });
  }

  notifyChangeRoomPlan = async () => {
    const { translate } = this.props;

    const result = await NotifyChangeRoomPlan();

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: translate('SUCCESS'),
        text: translate('REQUEST_INCREASE_ROOM_PLAN')
      });
      this.confirmAfterSave();
    } else {
      this.confirmAfterSave();
    }
  }

  onSubmit = async (e, endAction = null) => {
    if (e) e.preventDefault();
    const { history, translate, dispatch } = this.props;
    const { tables, notes, kitchenNotes, foodRestrictions, ourGuest } = this.state;

    const requiredFood = foodRestrictions?.length > 0
      ? foodRestrictions.filter(f => f?.notesRequired).map(m => m?._id)
      : [];

    const selectedRestrict = tables?.length > 0
      ? tables?.map(m => m?.seats).flat().filter(f => f?.guestName?.trim() !== '' && f?.foodRestrictions?.length > 0).map(s => s?.foodRestrictions).flat()
      : [];

    if (selectedRestrict.filter(f => requiredFood.includes(f)).length > 0 && kitchenNotes?.trim() == '') {
      this.setState({
        notesActiveTab: '1',
        invalidKitchenNotes: true
      });

      return Alerts.new({
        type: 'warning',
        title: translate('ATTENTION'),
        text: translate('WARNING_KITCHEN_NOTES')
      });
    } else {
      this.setState({ invalidKitchenNotes: false });
    }

    try {
      this.setState({ loading: true, saving: true, submittedForm: true });
      const data = {
        notes,
        kitchenNotes,
        tables,
        fromTables: true,
        roomPlanVersion: ourGuest?.roomPlanVersion,
      };

      // if (values?.invalid) {
      //   return Alerts.new({
      //     type: 'warning',
      //     title: translate('ATTENTION'),
      //     text: translate('ERROR_INVALID_ROOM_PLAN')
      //   });
      // }

      const result = await SaveRoomPlanEditor(data);

      if (result?.success) {
        Alerts.new({
          type: 'success',
          title: translate('SUCCESS'),
          text: translate('GUEST_MAP_SAVED')
        });

        if (result?.data?.answerRestriction) {
          dispatch(updateQuestionAction('FOOD', result?.data?.answerRestriction));
        }

        this.setState({
          saving: false,
          loading: false,
          dirtyForm: false,
          submittedForm: false,
          ourGuest: result?.data?.ourGuest || {},
          notes: result?.data?.ourGuest?.notes || '',
          kitchenNotes: result?.data?.ourGuest?.kitchenNotes || '',
          tables: result?.data?.tables || []
        }, () => {
          if (endAction == 'COUPLE') this.openModal();
          else if (endAction == 'SUBMIT_PLAN') this.submitRoomPlan();
          else if (result?.data?.notifyChangePlan) this.confirmNotifyChangeRoomPlan();
          else this.confirmAfterSave();
        });
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate(result?.message)
        });
        this.setState({
          saving: false,
          submittedForm: false,
          loading: false,
          showModal: false
        }, () => {
          if (result?.message == 'NOT_AUTHORIZED' || result?.message == 'OLD_ROOM_PLAN_VERSION'
            || result?.message == 'ROOM_PLAN_BLOCKED') {
            this.getInformation();
          }
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  /** Submit guest map and room plan */
  checkSubmitRoomPlan = async () => {
    const { ourGuest } = this.state;
    const { translate } = this.props;

    if (!ourGuest?.submittedRoomPlan) {
      const resultSubmit = await CheckSubmitRoomPlan();
      if (resultSubmit?.success) {
        // Show modal to answer table theme question
        if (resultSubmit?.data?.blockSubmit) {
          this.openModal(true);
        } else {
          this.confirmSubmitRoomPlan();
        }
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate(resultSubmit?.message)
        });
        return;
      }
    } else {
      return;
    }
  }

  confirmSubmitRoomPlan = async () => {
    const { translate } = this.props;

    const { dirtyForm, submittedForm } = this.state;
    if (dirtyForm && !submittedForm) {
      return this.onSubmit(null, 'SUBMIT_PLAN')
    }

    confirm({
      title: translate('MARK_AS_COMPLETED_QUESTION'),
      content: (<div>
        <p>{translate('MARK_AS_COMPLETED_CONTENT')}</p>
      </div>
      ),
      okText: translate('MARK_AS_COMPLETED'),
      cancelText: translate('CANCEL'),
      onOk: () => {
        this.submitRoomPlan();
      },
      onCancel: () => null,
    });
  }

  submitRoomPlan = async () => {
    const { translate } = this.props;
    this.setState({
      saving: true,
      submiting: true,
      loading: true
    });

    const resultSubmit = await SubmitRoomPlan();

    if (resultSubmit?.success) {
      Alerts.new({
        type: 'success',
        title: translate('SUCCESS'),
        text: translate('ROOM_PLAN_COMPLETED')
      });
      this.setState({
        submiting: false,
        saving: false,
        loading: false,
      }, () => this.getInformation());
    } else {
      Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate(resultSubmit?.data)
      });
      this.setState({
        submiting: false,
        saving: false,
        loading: false
      }, () => this.getInformation());
    }

  }

  /** Stats */
  getFoodRestrictionsStats = () => {
    const { activeLanguage, translate } = this.props;
    const { foodRestrictions, tables } = this.state;
    const seatsWithFoodRestrictions = tables?.length > 0
      ? tables?.filter(f => f?.active && !f?.removeBecauseOfOvalL).map(m => m?.seats).flat().filter(f => f?.guestName?.trim() != '' && f?.foodRestrictions?.length > 0)
      : [];
    const seatsWithFoodRestrictionsPopulated = seatsWithFoodRestrictions.map(m => ({
      ...m,
      foodRestrictions: foodRestrictions.filter(f => m.foodRestrictions.includes(f?._id))
    })).filter(f => f?.foodRestrictions?.length > 0);

    return seatsWithFoodRestrictionsPopulated?.length > 0
      ? getSummaryFoodRestrictions(seatsWithFoodRestrictionsPopulated, activeLanguage)
      : `<div>${translate('NO_DIETARY_RESTRICTIONS')}</div>`;
  }

  getStats = (type) => {
    const { tables } = this.state;
    const seats = tables?.length > 0
      ? tables?.filter(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose != TablePurposeEnum.STAFF).map(m => m?.seats).flat().filter(f => f?.guestName?.trim() != '')
      : [];

    let count = 0;
    switch (type) {
      case StatsEnum.ADULT:
        count = seats.filter(f => f?.guestAge == GuestAgeEnum.ADULT)?.length;
        break;
      case StatsEnum.NEWBORN:
        count = seats.filter(f => (f?.guestAge == GuestAgeEnum.NEWBORN || f?.guestAge == GuestAgeEnum.BABY))?.length;
        break;
      case StatsEnum.CHILD:
        count = seats.filter(f => f?.guestAge == GuestAgeEnum.CHILD)?.length;
        break;
      case StatsEnum.STAFF:
        const seatsStaff = tables?.find(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose == TablePurposeEnum.STAFF)?.seats?.filter(f => f?.guestName?.trim() != '');
        count = seatsStaff?.length || 0;
        break;
      case StatsEnum.TABLES:
        count = tables.filter(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose != TablePurposeEnum.STAFF)
          .filter(f => f?.seats?.length > 0 && f?.seats.find(f2 => f2?.guestName?.trim() != ''))
          .map(m => m?.value).reduce((a, b) => a + b, 0);
        break;
      case StatsEnum.GUESTS:
        count = seats?.length || 0;
        break;
      default:
        count = 0;
        break;
    }
    return count;
  }

  getPax = () => {
    const countAdults = this.getStats(StatsEnum.ADULT);
    const countChildren = this.getStats(StatsEnum.CHILD);
    const countStaff = this.getStats(StatsEnum.STAFF);
    return (countAdults + (countChildren / 2) + (countStaff / 2)) || 0;
  }

  getAvgPaxTables = () => {
    const totalSeatsFilled = this.getStats(StatsEnum.GUESTS);
    const totalTablesActive = this.getStats(StatsEnum.TABLES);
    return (totalSeatsFilled / totalTablesActive) || 0;
  }

  getChefFoodRestrictions = () => {
    const { activeLanguage, translate } = this.props;
    const { foodRestrictions } = this.state;
    return `${translate('DIETARY_RESTRICTIONS')}: ${foodRestrictions?.filter(f => f?.chefChoice && f?.acronym).map(m => m?.acronym[activeLanguage.code]).join(', ')}`;
  }

  /* Tables */
  reducerTables = (action) => {
    // console.log("reducerTables", action);
    let { tables, ourGuest, blocked } = this.state;
    if (ourGuest?.validatedRoomPlan || blocked) return;

    switch (action.type) {
      case 'UPDATE_TABLE':
        const updatedTables = [...tables];
        const indexTable = tables?.findIndex(f => f?.code == action.code);
        if (indexTable > -1) {
          updatedTables[indexTable] = action.value;
          this.setState({ tables: updatedTables, dirtyForm: true });
        }
        return updatedTables;
      case 'UPDATE_TABLE_NAME':
        const updatedTablesName = [...tables];
        const indexTableName = tables?.findIndex(f => f?.code == action.code);
        if (indexTableName > -1) {
          updatedTablesName[indexTableName] = { ...tables[indexTableName], name: action.value };
          this.setState({ tables: updatedTablesName, dirtyForm: true });
        }
        return updatedTablesName;
      case 'UPDATE_SEATS_FRONT_COUPLE':
        const updatedSeatsFrontCouples = [...tables];
        const indexSeatFrontCouple = tables?.findIndex(f => f?.code == action.code);
        if (indexSeatFrontCouple > -1) {
          updatedSeatsFrontCouples[indexSeatFrontCouple] = { ...tables[indexSeatFrontCouple], seatsFrontCouple: action.value };
          this.setState({ tables: updatedSeatsFrontCouples, dirtyForm: true });
        }
        return updatedSeatsFrontCouples;
      case 'UPDATE_TABLE_PURPOSE':
        const updatedTablesPurpose = [...tables];
        const indexTablePurpose = tables?.findIndex(f => f?.code == action.code);
        if (indexTablePurpose > -1) {
          updatedTablesPurpose[indexTablePurpose] = { ...tables[indexTablePurpose], tablePurpose: action.value };
          this.setState({ tables: updatedTablesPurpose, dirtyForm: true });
        }
        return updatedTablesPurpose;
      case 'UPDATE_SEAT_NAME':
        const updatedTablesSeatName = [...tables];
        const indexTableSeatName = tables?.findIndex(f => f?.code == action.code);
        if (indexTableSeatName > -1) {
          const tableSeat = { ...tables[indexTableSeatName] };
          tableSeat.seats[action.seatIndex].guestName = action.value;
          updatedTablesSeatName[indexTableSeatName] = tableSeat;
          this.setState({ tables: updatedTablesSeatName, dirtyForm: true });
        }
        return updatedTablesSeatName;
      case 'ADD_TABLE_SEATS':
        const addTablesSeat = [...tables];
        const indexTableAddSeat = tables?.findIndex(f => f?.code == action.code);
        if (indexTableAddSeat > -1) {
          const tableAddSeat = { ...tables[indexTableAddSeat] };
          tableAddSeat.seats.push(action.value);
          addTablesSeat[indexTableAddSeat] = tableAddSeat;
          this.setState({ tables: addTablesSeat, dirtyForm: true });
        }
        return addTablesSeat;
      case 'UPDATE_SEATS':
        const updatedTablesSeatsAll = [...tables];
        const indexTableSeats = tables?.findIndex(f => f?.code == action.code);
        if (indexTableSeats > -1) {
          updatedTablesSeatsAll[indexTableSeats] = { ...tables[indexTableSeats], seats: action.value };
          this.setState({ tables: updatedTablesSeatsAll, dirtyForm: true });
        }
        return updatedTablesSeatsAll;
      case 'UPDATE_SEAT':
        const updatedTablesSeats = [...tables];
        const indexTableSeat = tables?.findIndex(f => f?.code == action.code);
        if (indexTableSeat > -1) {
          updatedTablesSeats[indexTableSeat].seats[action.seatIndex] = { ...action.value };
          this.setState({ tables: updatedTablesSeats, dirtyForm: true });
        }
        return updatedTablesSeats;
      case 'REMOVE_SEAT':
        const removedTablesSeats = [...tables];
        const indexTableRSeat = tables?.findIndex(f => f?.code == action.code);
        if (indexTableRSeat > -1) {
          removedTablesSeats[indexTableRSeat].seats.splice(action.seatIndex, 1);
          this.setState({ tables: removedTablesSeats, dirtyForm: true });
        }
        return removedTablesSeats;
      default:
        return tables;
    }
  }

  selectToChangeGuests = (code, table) => {
    const { translate } = this.props;
    const { tables, ourGuest, blocked } = this.state;
    if (ourGuest?.validatedRoomPlan || blocked) return;

    const selectedToChange = tables?.find(f => f?.active && !f?.removeBecauseOfOvalL && f?.isSelectedToChange);
    if (selectedToChange) {
      const canChangeTables = canChangeSeats(selectedToChange, table);
      if (canChangeTables) {
        confirm({
          title: translate('CHANGE_GUESTS_QUESTION'),
          content: (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <TableGuestsModal>
                <TableGuestsHeader modal={true}>
                  <TableSeatCode seat={false} couple={false}>{convertNumberToLetter(selectedToChange?.code || 0) || ''}</TableSeatCode>
                  <TableName>{selectedToChange?.name}</TableName>
                </TableGuestsHeader>
                {selectedToChange?.seats?.map((m, index) => (
                  <SeatRow key={index} disabled={false} modal={true}>
                    <TableSeatCode modal={true} couple={false} seat={true}>{(m?.number || 0) + 1 || ''}</TableSeatCode>
                    <SeatName modal={true}>{m?.guestName}</SeatName>
                  </SeatRow>
                ))}
              </TableGuestsModal>
              <Icon type="swap" style={{ fontSize: 25, margin: 'auto 8px' }} />
              <TableGuestsModal>
                <TableGuestsHeader modal={true}>
                  <TableSeatCode seat={false} couple={false}>{convertNumberToLetter(table?.code || 0) || ''}</TableSeatCode>
                  <TableName>{table?.name}</TableName>
                </TableGuestsHeader>
                {table?.seats?.map((m, index) => (
                  <SeatRow key={index} disabled={false} modal={true}>
                    <TableSeatCode modal={true} couple={false} seat={true}>{(m?.number || 0) + 1 || ''}</TableSeatCode>
                    <SeatName modal={true}>{m?.guestName}</SeatName>
                  </SeatRow>
                ))}
              </TableGuestsModal>
            </div>
          ),
          className: 'change-guests-modal',
          okType: 'primary',
          okText: translate('CHANGE_GUESTS'),
          cancelText: translate('CANCEL'),
          onOk: () => this.changeGuests(selectedToChange, code),
          onCancel: () => this.cancelChangeGuests(),
        });
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate('ERROR_CHANGE_GUESTS')
        });
        this.cancelChangeGuests();
      }

    } else {
      const updatedTables = [...tables];
      const index = updatedTables.findIndex(f => f?.code == table?.code);
      const updatedTable = { ...tables[index] };
      updatedTable.isSelectedToChange = !table?.isSelectedToChange;
      this.reducerTables({
        type: 'UPDATE_TABLE',
        index,
        value: updatedTable
      });
      updatedTables.splice(index, 1, updatedTable);
      this.setState({ tables: updatedTables, dirtyForm: true });
    }
  }

  cancelChangeGuests = () => {
    const { tables } = this.state;
    let updatedTables = [...tables];
    const selectedTablesToChange = tables?.filter(f => f?.isSelectedToChange);

    for (let i = 0; i < selectedTablesToChange.length; i++) {
      const selectedTable = selectedTablesToChange[i];
      const index = tables.findIndex(f => f?.code == selectedTable?.code);
      if (index > -1) {
        const tableToUnselect = { ...selectedTable };
        tableToUnselect.isSelectedToChange = false;
        updatedTables.splice(index, 1, tableToUnselect);
      }
    }

    this.setState({ tables: updatedTables, dirtyForm: true });
  }

  changeGuests = (selectedTable, code) => {
    const { tables, ourGuest, blocked } = this.state;
    if (ourGuest?.validatedRoomPlan || blocked) return;

    const updatedTables = [...tables];
    const indexTable = tables.findIndex(f => f?.code == code);
    const table = { ...tables[indexTable] };
    const indexSelected = tables.findIndex(f => f?.code == selectedTable?.code);
    const selectedTableToChange = { ...tables[indexSelected] };

    const auxTable = JSON.parse(JSON.stringify(table));

    for (let index = 0; index < table?.seats.length; index++) {
      const newSeat = selectedTableToChange?.seats?.[index]
        ? selectedTableToChange?.seats[index]
        : null;
      table.seats[index] = newSeat
        ? newSeat
        : {
          code: index,
          number: index,
          couple: false,
          guestName: '',
          guestAge: GuestAgeEnum.ADULT,
          foodRestrictions: []
        };
    }
    updatedTables.splice(indexTable, 1, { ...table, name: selectedTableToChange?.name });

    for (let index = 0; index < selectedTableToChange?.seats.length; index++) {
      const newSeat = auxTable?.seats?.[index]
        ? auxTable?.seats[index]
        : null;
      selectedTableToChange.seats[index] = newSeat
        ? newSeat
        : {
          code: index,
          number: index,
          couple: false,
          guestName: '',
          guestAge: GuestAgeEnum.ADULT,
          foodRestrictions: []
        };
    }
    updatedTables.splice(indexSelected, 1, { ...selectedTableToChange, name: auxTable?.name });

    this.setState({ tables: updatedTables, dirtyForm: true }, () => {
      this.cancelChangeGuests();
    });
  }

  activateTable = (code) => {
    const { translate } = this.props;
    const { tables, ourGuest, blocked } = this.state;
    if (ourGuest?.validatedRoomPlan || blocked) return;

    let updatedTables = [...tables];
    const index = tables.findIndex(f => f?.code == code);
    const tableToActive = { ...tables[index] };
    tableToActive.active = !tableToActive.active;

    if (!tableToActive?.active) {
      tableToActive.name = '';
      tableToActive.seats = tableToActive?.seats?.map(m => ({
        number: m?.number,
        guestName: '',
        guestAge: GuestAgeEnum.ADULT,
        foodRestrictions: []
      }));
    }

    if (tableToActive?.active && tableToActive?.tableType == 'RectangularLTable') {
      const tablesIncreased = tables?.filter(f => f?.active && f?.tableType == 'RectangularLTable')?.length || 0;
      if (tablesIncreased >= 2) {
        return Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate('ERROR_INCREASE_TABLE')
        });
      }
    }

    updatedTables.splice(index, 1, tableToActive);

    // Only show cost extra warning when activating table
    // if (tableToActive?.active) {
    //   const extraTables = checkExtraCostByTables(updatedTables);
    //   if (extraTables > 0) {
    //     Alerts.new({
    //       type: 'warning',
    //       title: translate('ATTENTION'),
    //       text: translate('ACTIVATED_TABLE_EXTRA_COST')
    //     });
    //   }
    // }

    if (this.roomEditor) {
      if (tableToActive?.active) this.roomEditor.enableTableByCode(tableToActive.code)
      else this.roomEditor.disableTableByCode(tableToActive.code);
    }

    this.setState({ tables: updatedTables, dirtyForm: true });
  }

  changeTablePurpose = (purpose, code) => {
    const { translate } = this.props;
    const { tables, ourGuest, blocked } = this.state;
    if (ourGuest?.validatedRoomPlan || blocked) return;

    if (purpose == TablePurposeEnum.STAFF) {
      const index = tables?.findIndex(f => f?.code == code);
      if (index < -1) return;

      const table = tables[index];
      const existStaff = tables.find(f => f?.tablePurpose == TablePurposeEnum.STAFF && f?.code !== table?.code) ? true : false;;
      if (existStaff) {
        Alerts.new({
          type: 'warning',
          title: translate('ATTENTION'),
          text: translate('ERROR_ONLY_1_STAFF')
        });
      }

      this.reducerTables({
        type: 'UPDATE_TABLE_PURPOSE',
        code,
        value: tables[index]?.tablePurpose || TablePurposeEnum.GUEST
      });
    }
  }

  checkAddTable = () => {
    const { tables } = this.state;
    const listActives = tables?.filter(f => f?.active && !f?.removeBecauseOfOvalL && (f?.tablePurpose != TablePurposeEnum.COUPLE && f?.tablePurpose != TablePurposeEnum.STAFF));
    listActives.sort((a, b) => a?.code - b?.code);
    const lastTableActive = listActives?.length > 0
      ? listActives[listActives.length - 1]
      : null;
    const tablesToActive = tables?.filter(f => !f?.active && !f?.removeBecauseOfOvalL && (f?.tablePurpose != TablePurposeEnum.COUPLE && f?.tablePurpose != TablePurposeEnum.STAFF))
      .filter(f => f?.code > lastTableActive?.code);
    tablesToActive.sort((a, b) => a?.code - b?.code);
    const nextTableToActive = tablesToActive?.length > 0
      ? tablesToActive[0]
      : null;

    return nextTableToActive && nextTableToActive?.code ? true : false;
  }

  openImportModal = (code) => {
    this.setState({
      showImportModal: true,
      importModalTable: {
        code,
        names: ''
      }
    });
  }

  submitImportModal = (value) => {
    const { translate } = this.props;
    let { tables } = this.state;
    this.setState({ importingGuests: true });

    const indexTable = tables?.findIndex(f => f?.code == value?.code);
    if (indexTable > -1) {
      const updatedTables = [...tables];
      const tableToUpdate = tables[indexTable];

      const namesToImport = value.names.split(/\r?\n/) || [];

      if (tableToUpdate?.tablePurpose != TablePurposeEnum.STAFF) {
        let seatsToCheck = [...tableToUpdate?.seats];
        if (!tableToUpdate?.seatsFrontCouple) {
          seatsToCheck = seatsToCheck.filter(f => !f?.frontCouple);
        }

        if (seatsToCheck?.length < namesToImport?.length) {
          Alerts.new({
            type: 'error',
            title: translate('ERROR'),
            text: translate('ERROR_IMPORT_GUESTS')
          });
          this.setState({
            showImportModal: false,
            importingGuests: false,
            importModalTable: null
          });
          return;
        } else {
          tableToUpdate.seats = tableToUpdate.seats.map(m => ({ ...m, guestName: '' }));
          for (let index = 0; index < namesToImport.length; index++) {
            const name = namesToImport[index];
            const emptySeatIndex = tableToUpdate?.seatsFrontCouple
              ? tableToUpdate?.seats.findIndex(f => f?.guestName.trim() == '')
              : tableToUpdate?.seats?.findIndex(f => !f?.frontCouple && f?.guestName.trim() == '');
            if (emptySeatIndex > -1) {
              tableToUpdate.seats[emptySeatIndex].guestName = name;
              tableToUpdate.seats[emptySeatIndex].guestAge = GuestAgeEnum.ADULT;
              tableToUpdate.seats[emptySeatIndex].foodRestrictions = [];
            }
          }
        }
      } else {
        tableToUpdate.seats = tableToUpdate.seats.map(m => ({ ...m, guestName: '' }));
        for (let index = 0; index < namesToImport.length; index++) {
          const name = namesToImport[index];
          const emptySeatIndex = tableToUpdate?.seats.findIndex(f => f?.guestName.trim() == '');
          if (emptySeatIndex > -1) {
            tableToUpdate.seats[emptySeatIndex].guestName = name;
            tableToUpdate.seats[emptySeatIndex].guestAge = GuestAgeEnum.ADULT;
            tableToUpdate.seats[emptySeatIndex].foodRestrictions = [];
          } else {
            tableToUpdate.push({
              number: tableToUpdate?.seats?.length,
              couple: false,
              guestName: name,
              guestAge: GuestAgeEnum.ADULT,
              foodRestrictions: []
            });
          }
        }
      }

      updatedTables[indexTable] = { ...tableToUpdate };
      this.setState({
        tables: updatedTables,
        dirtyForm: true,
        showImportModal: false,
        importingGuests: false,
        importModalTable: null
      }, () => {
        return Alerts.new({
          type: 'success',
          title: translate('SUCCESS'),
          text: translate('SUCCESS_IMPORT_GUESTS')
        });
      });
    } else {
      this.setState({
        showImportModal: false,
        importingGuests: false,
        importModalTable: null
      });
      return;
    }
  }

  showAlert = () => {
    const { translate } = this.props;
    const { blocked, ourGuest } = this.state;

    if (ourGuest?.validatedRoomPlan) {
      return Alerts.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('ROOM_PLAN_APPROVED')
      });
    }
    else if (blocked) {
      return Alerts.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        // text: translate('BLOCKED_ANSWER_TEXT')
      });
    }
  }

  // Couple table 
  openModal = async (fromSubmit = false) => {
    const { dispatch } = this.props;
    const { coupleTableQuestion, tableThemeQuestion } = this.state;
    const { dirtyForm, submittedForm } = this.state;

    if (dirtyForm && !submittedForm) {
      return this.onSubmit(null, 'COUPLE');
    }

    dispatch(initialize('manage_answers_form', {}));

    const question = fromSubmit
      ? tableThemeQuestion
      : coupleTableQuestion;

    if (question?.answer) {
      dispatch(
        initialize('manage_answers_form', {
          ...question?.answer,
          question_obs: question?.answer?.observations !== ''
        })
      );
    }

    this.setState({
      showModal: true,
      questionModal: question,
    });
  };

  confirmSubmitModal = async (values) => {
    const { translate, wedding } = this.props;
    const { tables, questionModal } = this.state;

    if (questionModal?.isTableTheme) {
      this.saveModal(values);
    } else {
      const data = checkCoupleTableAnswer(values, questionModal, wedding.wedding_place);
      const resultCheck = await CheckUpdateCoupleTable(data);

      if (resultCheck?.success) {
        if (resultCheck?.data?.showWarning) {
          const ovalLWarning = resultCheck?.data?.showOvalLWarning
            ? resultCheck?.data?.ovalLWarning == 'TO_REMOVE_TABLES_BY_OVALL'
              ? translate('TO_REMOVE_TABLES_BY_OVALL')?.replace('#tables#', resultCheck?.data?.tablesToRemove)
              : resultCheck?.data?.ovalLWarning == 'TO_RESTORE_TABLES_BY_OVALL'
                ? translate('TO_RESTORE_TABLES_BY_OVALL')?.replace('#tables#', resultCheck?.data?.tablesToRemove)
                : null
            : null;

          if (resultCheck?.data?.showOvalLWarning && resultCheck?.data?.tablesToRemoveFilled) {
            this.setState({ showModal: false }, () => {
              Modal.warning({
                title: translate('ATTENTION'),
                content: translate('COUPLE_REMOVE_WITH_OVALL_BLOCKED'),
              });
            })
            return;
          }

          confirm({
            title: translate('UPDATE_COUPLE_TABLE_QUESTION'),
            content: (<div>
              <p>{translate('UPDATE_COUPLE_TABLE_CONTENT')}</p>
              {resultCheck?.data?.showOvalLWarning && ovalLWarning && <p><b>{ovalLWarning}</b></p>}
            </div>
            ),
            okText: translate('CHANGE_TABLE'),
            cancelText: translate('CANCEL'),
            onOk: () => this.saveModal(values),
            onCancel: () => this.setState({ showModal: false }),
          });
        } else {
          this.saveModal(values);
        }
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate(resultCheck.message)
        })
        this.cancelModal();
      }
    }

  }

  cancelModal = () => {
    this.setState({
      questionModal: null,
      showModal: false,
    });
  }

  saveModal = async (values) => {
    const { dispatch, translate, wedding } = this.props;
    const { questionModal, objectTypes } = this.state;
    try {
      this.setState({ savingModal: true });
      values = removeNotExistingOptions(values, questionModal, wedding?.wedding_place);
      values = correctResponseValues(values);
      const payload = TransformToFormData({ ...values, question: questionModal._id });

      const result = await AnswerQuestion(payload);

      if (result?.success) {
        dispatch(updateQuestionAction('DECORATION', result.data));
        this.setState({
          savingModal: false,
          showModal: false,
          questionModal: null,
        }, () => {
          if (questionModal?.isTableTheme) {
            this.confirmSubmitRoomPlan();
          } else {
            this.onSubmit(null)
          }
        });
      } else if (!result.success && result?.message === "WEDDING_OPTION_NOT_AVAILABLE") {
        Alerts.new({
          type: "",
          title: translate(result.message),
          text: ''
        });
      }
      this.setState({ savingModal: false });
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    }
  };

  render() {
    const { translate, activeLanguage, menuOpen } = this.props;
    const { history } = this.props;
    const { wedding, weddingInfo, tablesForm, weddingDate } = this.props;
    const { dirtyForm, submittedForm } = this.state;
    const { chapter, nextChapter, prevChapter } = this.state;
    const { isProcessClosed, canEditSection, blockedSubmit } = this.state;
    const { ourGuest, foodRestrictions, tables, objectTypes } = this.state;
    const { notesActiveTab, notes, kitchenNotes, invalidKitchenNotes } = this.state;
    const { openPolicy } = this.state;
    const { ready, loading, blocked, addingTable, saving, submiting } = this.state;
    const { showModal, questionModal, savingModal } = this.state;
    const { showImportModal, importingGuests, importModalTable } = this.state;
    const { showRestrictionModal } = this.state;
    const canAdd = this.checkAddTable();
    const hasExtraCost = checkExtraCostByTables(tables) > 0 ? true : false;
    const extraTables = calculateExtraTables(tables);

    // if (!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <LeavePageBlocker
          when={dirtyForm && !submittedForm}
          message={translate("UNSAVED_OPTION_CHANGES")}
        />
        {ready && <DecorationNavigation
          returnUrl={returnURL}
          mainCategoryId={chapterId}
          weddingDate={weddingDate}
          validatedRoomPlan={ourGuest?.validatedRoomPlan}
          mainCategory={chapter.name[activeLanguage?.code]}
          currentQuestion={chapter.name[activeLanguage?.code]}
          currentQuestionUrl={`${returnURL}/${chapterId}`}
          nextQuestion={nextChapter?.name[activeLanguage?.code]}
          nextQuestionUrl={`${returnURL}/${nextChapter?._id}`}
          prevQuestion={prevChapter?.name[activeLanguage?.code]}
          prevQuestionUrl={`${returnURL}/${prevChapter?._id}`}
        />}
        <RoomPlanContainer guestMap={true}>
          {loading && <SpinLoading />}
          <FormContainer onSubmit={(e) => this.onSubmit(e)} style={{ display: loading ? 'none' : 'block' }}>
            <Row gutter={[12, 12]} style={{ display: 'flex', alignContent: 'stretch', flexWrap: 'wrap' }}>
              <Col xs={{ span: 24, order: 2 }} md={{ span: 14, order: 1 }} className="col-chefe">
                <Button
                  type='default'
                  style={{ marginRight: 8 }}
                  onClick={() => this.setState({ showRestrictionModal: true })}>
                  <div style={{ display: 'inline-flex' }}>
                    <ReactSVG className='category-icon' src={DietaryRestrictionsIcon} />
                    <span style={{ marginLeft: 4 }}>{translate('DIETARY_RESTRICTIONS')}</span>
                  </div>
                </Button>
                <Tooltip title={this.getChefFoodRestrictions()}>
                  <Icon type="info-circle" />
                  {translate('CHEF_CHOICE')}
                </Tooltip>
              </Col>
              <Col xs={{ span: 24, order: 1 }} md={{ span: 10, order: 2 }} className="col-terms">
                <a onClick={() => this.setState({ openPolicy: true })}>
                  <Icon type="info-circle" />
                  {translate('TERMS_CONSIDERATIONS_WD')}
                </a>
                {blockedSubmit
                  ? <Tooltip title={translate('BLOCKED_BTN_SUBMIT_GUESTS')}>
                    <Button
                      type='primary'
                      style={{ marginLeft: 8 }}
                      disabled={blockedSubmit || blocked || ourGuest?.submittedRoomPlan || ourGuest?.validatedRoomPlan || loading || saving}
                      onClick={this.checkSubmitRoomPlan}>
                      {submiting ? <Icon type="loading" /> : <i className="fa-solid fa-file-circle-check"></i>}
                      <span style={{ marginLeft: 4 }}>{translate('MARK_AS_COMPLETED')}</span>
                    </Button>
                  </Tooltip>
                  : <Button
                    type='primary'
                    style={{ marginLeft: 8 }}
                    disabled={blockedSubmit || blocked || ourGuest?.submittedRoomPlan || ourGuest?.validatedRoomPlan || loading || saving}
                    onClick={this.checkSubmitRoomPlan}>
                    {submiting ? <Icon type="loading" /> : <i className="fa-solid fa-file-circle-check"></i>}
                    <span style={{ marginLeft: 4 }}>{translate('MARK_AS_COMPLETED')}</span>
                  </Button>}
                {/* {(!ourGuest?.validatedRoomPlan && !blocked) ? <BaseButton
                      type={'dark'}
                      icon={'save'}
                      text={translate("SAVE")}
                      disabled={blocked || loading || saving || submiting}
                      onClick={this.saveRoomPlan}
                    />
                      : <BaseButton
                        type={'dark'}
                        // icon={'arrow-right'}
                        text={translate("CONTINUE")}
                        disabled={loading || saving || submiting}
                        onClick={() => history.push(`${returnURL}/${nextChapter?._id}`)}
                      />}
                  </div> */}
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={6} order={3} style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                <RoomEditorContent noBorder={true}>
                  <Tabs type="card" onChange={this.changeNotesActiveTab} activeKey={notesActiveTab}>
                    <TabPane tab={translate('ROOM_PLAN_KITCHEN_NOTES')} key="1">
                      <TextAreaInput
                        // label={translate('ROOM_PLAN_KITCHEN_NOTES')}
                        placeholder={translate('MESSAGE_WD')}
                        fillError={true}
                        meta={{ invalid: invalidKitchenNotes, submitFailed: invalidKitchenNotes }}
                        readOnly={ourGuest?.validatedRoomPlan || blocked}
                        input={{
                          value: kitchenNotes,
                          onChange: (e) => this.handleKitchenNotesChange(e),
                        }}
                        onClick={() => this.showAlert()}
                      />
                    </TabPane>
                    <TabPane tab={translate('ROOM_PLAN_NOTES')} key="2">
                      <TextAreaInput
                        // label={translate('ROOM_PLAN_NOTES')}
                        placeholder={translate('MESSAGE_WD')}
                        meta={{ invalid: false, submitFailed: false }}
                        readOnly={ourGuest?.validatedRoomPlan || blocked}
                        input={{
                          value: notes,
                          onChange: (e) => this.handleNotesChange(e),
                        }}
                        onClick={() => this.showAlert()}
                      />
                    </TabPane>
                  </Tabs>
                </RoomEditorContent>
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={4} order={4} style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                <RoomEditorContent>
                  <h3 className="title">{translate('AUTO_COUNTING')}</h3>
                  <span className="text">{translate('AUTO_COUNTING_FOOD_RESTRICTIONS')}</span>
                  <div className="info-content" dangerouslySetInnerHTML={{
                    __html: this.getFoodRestrictionsStats(),
                  }}>
                  </div>
                </RoomEditorContent>
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} order={5} style={{ minHeight: '100%', display: 'flex' }}>
                <GuestMapStats avgError={(this.getAvgPaxTables() < 10) ? true : false}>
                  <h3 className="title">{translate('AUTO_COUNTING')}</h3>
                  <span className="text">{translate('AUTO_COUNTING_TEXT')}</span>
                  <StatsContentRow global={true}>
                    <div className='stats-description'>{translate('ADULT_AGE')}</div>
                    <div className='stats-value'>{this.getStats(StatsEnum.ADULT)}</div>
                  </StatsContentRow>
                  <StatsContentRow global={true}>
                    <div className='stats-description'>{translate('FROM_CHILD_AGE')}</div>
                    <div className='stats-value'>{this.getStats(StatsEnum.CHILD)}</div>
                  </StatsContentRow>
                  <StatsContentRow global={true}>
                    <div className='stats-description'>{translate('FROM_NEWBORN_CHILD_AGE')}</div>
                    <div className='stats-value'>{this.getStats(StatsEnum.NEWBORN)}</div>
                  </StatsContentRow>
                  <StatsContentRow global={true}>
                    <div className='stats-description'>{translate('ROOM_PLAN_STAFF')}</div>
                    <div className='stats-value'>{this.getStats(StatsEnum.STAFF)}</div>
                  </StatsContentRow>
                  <StatsContentRow global={true}>
                    <div className='stats-description'>{translate('ROOM_PLAN_TOTAL_TABLES')}</div>
                    <div className='stats-value'>{this.getStats(StatsEnum.TABLES)}</div>
                  </StatsContentRow>
                  <StatsContentRow global={true}>
                    <div className='stats-description'>{translate('ROOM_PLAN_TOTAL_GUESTS')}</div>
                    <div className='stats-value'>{this.getStats(StatsEnum.GUESTS)}</div>
                  </StatsContentRow>
                  <StatsContentRow global={true}>
                    <div className='stats-description'>{translate('AVG_PAX_TABLES')}</div>
                    <div className='stats-value avg-value'>{this.getAvgPaxTables()?.toFixed(2)}</div>
                  </StatsContentRow>
                  <StatsContentRow global={true}>
                    <div className='stats-description'>{translate('ROOM_PLAN_TOTAL_PAX')}</div>
                    <div className='stats-value total-pax'>{this.getPax()?.toFixed(2)}</div>
                  </StatsContentRow>
                </GuestMapStats>
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} order={6} style={{ minHeight: '100%', display: 'flex' }}>
                {hasExtraCost && <CostExtraMessage>
                  <img src={CostExtraIcon} alt="icon" />
                  <div className="content">
                    <p className="title">{translate('COST_EXTRA_WARNING_TITLE')}</p>
                    {/* <p>{translate('COST_EXTRA_WARNING_DESCRIPTION')}</p> */}
                    <p><b>{translate('COST_EXTRA_WARNING_LABEL')}:</b> <Tag color="gold">{extraTables} {translate('TABLES')}</Tag></p>
                  </div>
                </CostExtraMessage>}
                {/* <RoomEditorContent>
                  <h3 className="title">{translate('ROOM_PLAN')}</h3>
                  <span className="text">{translate('PREVIEW_ROOM_PLAN_CONTENT')}</span>
                  <RoomEditorContainer guestMap={true}>
                    {(loading || !ready) && <div className='backdrop'>
                      <SpinLoading />
                    </div>}
                    <RoomEditor guestMap={true} id="editor"></RoomEditor>
                  </RoomEditorContainer>
                </RoomEditorContent> */}
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} order={7} style={{ minHeight: '100%', display: 'flex' }}>
                <RoomEditorContent>
                  <h3 className="title">{translate('ROOM_PLAN_TABLES')}</h3>
                  <div className="tables-search-content">
                    {tables.filter(f => !f?.removeBecauseOfOvalL)?.filter(f => f?.active).map((m, index) => (
                      <Link key={index} to={`anchor-${m?.code}`} isDynamic={true} spy={true} smooth={'easeInOutQuint'} offset={-130}>
                        <TableSearchCode active={m?.active}>{convertNumberToLetter(m?.code)}</TableSearchCode>
                      </Link>
                    ))}
                  </div>
                </RoomEditorContent>
              </Col>
              <Col xs={24} order={7}>
                <GuestTablesComponent
                  wedding={wedding}
                  roomEditor={this.roomEditor}
                  isValidated={ourGuest?.validatedRoomPlan}
                  roomPlanBlocked={blocked}
                  canAddTable={canAdd}
                  tables={tables}
                  objectTypes={objectTypes}
                  foodRestrictions={foodRestrictions}
                  parentActiveTable={this.activateTable}
                  editTableCouple={this.openModal}
                  updateTable={(action) => this.reducerTables(action)}
                  changeTablePurpose={this.changeTablePurpose}
                  parentSelectToChangeGuests={this.selectToChangeGuests}
                  parentCancelChangeGuests={this.cancelChangeGuests}
                  parentOpenImportModal={this.openImportModal}
                  translate={translate}
                  activeLanguage={activeLanguage} />
              </Col>
            </Row>

            {(!ourGuest?.validatedRoomPlan && !blocked) && (
              <SaveButton
                htmlType="submit"
                text={translate("SAVE_CONTINUE")}
                textMobile={translate("SAVE")}
                withBackground={true}
                loading={loading}
              />
            )}
          </FormContainer>
          {
            (ourGuest?.validatedRoomPlan || blocked) && (
              <SaveButton
                htmlType="button"
                text={translate("CONTINUE")}
                textMobile={translate("CONTINUE")}
                withBackground={true}
                onClick={() => history.push(`${returnURL}/${nextChapter?._id}`)}
              />
            )
          }

          {
            openPolicy && <GuestPolicyModal
              openModal={openPolicy}
              closeModal={() => this.setState({ openPolicy: false })}
              translate={translate}
              activeLanguage={activeLanguage}
            />
          }

          {
            showModal &&
            <SectionModal
              open={showModal}
              loading={savingModal}
              question={questionModal}
              canEditSection={canEditSection}
              isProcessClosed={isProcessClosed}
              onSubmit={this.confirmSubmitModal}
              closeModal={() => this.setState({ showModal: false })}
              translate={translate}
              activeLanguage={activeLanguage}
            />
          }

          {showImportModal &&
            <ImportGuestModal
              isOpen={showImportModal}
              loading={importingGuests}
              initialValues={importModalTable}
              onSubmit={this.submitImportModal}
              onClose={() => this.setState({ showImportModal: false, importModalTable: null })}
              translate={translate}
            />}

          {showRestrictionModal &&
            <FoodRestrictionsModal
              isOpen={showRestrictionModal}
              initialValues={[...foodRestrictions]}
              onClose={() => this.setState({ showRestrictionModal: false })}
              translate={translate}
              activeLanguage={activeLanguage} />}
        </RoomPlanContainer >
      </React.Fragment >
    );
  }
}

const mapStateToProps = (state) => ({
  structureInfo: state.wedding.structure,
  wedding: state.wedding.wedding,
  weddingDate: state.wedding.wedding.date,
  blockDate: state.wedding.wedding.blockDate,
});

const mapActionToProps = (dispatch) =>
  bindActionCreators({ updateQuestionAction, dispatch }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(
  withLocalize(withRouter(GuestsMapList))
);
