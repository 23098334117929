import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment, useReducer, useCallback } from 'react';
import { Row, Col, Icon, Tooltip, Modal, Input } from 'antd';
import { Field, FieldArray } from 'redux-form';
import { SectionTitle } from '../../styles/BasicStyles';
import { DeleteSeatButton, SeatAge, SeatFoodRestrict, SeatName, SeatRow, TableSeatCode } from './RoomPlanStyles';
import Alerts from '../../components/alert/Alert';
import SelectAgeInput from '../../components/inputs/SelectAgeInput';
import SelectRestrictionInput from '../../components/inputs/SelectRestrictionInput';
import { MaterialInput } from '../../components/inputs/MaterialStyles';
import { AgeTypes, GuestAgeEnum, TablePurposeEnum, setRestrictionsBasedOnAge } from './utilGuest';

const REQUIRED_RESTRICTIONS = [
  '6576f9f0e5c2e224a81aeaa1',  // VEGETARIAN
  '6576f9f0e5c2e224a81aeaa2',  // VEGAN
  '6576f9f1e5c2e224a81aeab6',  // CHILD MENU
  '67c03d27b272a8dfe4179381',  // FISH
  '67c03cceb272a8dfe4179380'  // MEAT
];

const GuestSeatComponent = ({
  wedding,
  validated,
  roomPlanClosed,
  index,
  seat,
  table,
  foodRestrictions,
  parentUpdateSeat,
  parentRemoveSeat,
  translate,
  dishes
}) => {
  const [tableObj, setTable] = useState(table);

  const [number, setNumber] = useState(seat?.number);
  const [guestName, setGuestName] = useState(seat?.guestName);
  const [guestAge, setGuestAge] = useState(seat?.guestAge);
  const [guestRestrictions, setGuestRestrictions] = useState(seat?.foodRestrictions);
  const [isCouple, setIsCouple] = useState(seat.couple);
  const [frontCouple, setFrontCouple] = useState(seat.frontCouple);

  const [disabled, setDisabled] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isValidated, setValidated] = useState(validated);
  const [isRoomPlanClosed, setRoomPlanClosed] = useState(roomPlanClosed);

  const [restrictionError, setRestrictionError] = useState(false);

  // useEffect(() => {
  //   if (wedding?.upgrade?.tag === 'TREND') {
  //     if (!disabled && guestName?.trim()) {
  //       const isValid = hasRequiredRestriction(seat?.foodRestrictions);
  //       setRestrictionError(!isValid);
  //     } else {
  //       setRestrictionError(false);
  //     }
  //   }
  // }, [seat?.foodRestrictions, guestName, disabled]);

  useEffect(() => {
    setNumber(seat?.number);
    setGuestName(seat?.guestName);
    setGuestAge(seat?.guestAge);
    setGuestRestrictions(reorderFoodRestrictions(seat?.foodRestrictions));
    setIsCouple(seat?.couple);
    setFrontCouple(seat?.frontCouple);
  }, [seat, seat?.guestName, seat?.guestAge, seat?.couple, seat?.frontCouple, seat?.foodRestrictions]);

  useEffect(() => {
    setTable(table);
    setDisabled(!table?.active);
    setIsStaff(table?.tablePurpose == TablePurposeEnum.STAFF);
  }, [table]);

  useEffect(() => {
    setValidated(validated);
  }, [validated]);

  useEffect(() => {
    setRoomPlanClosed(roomPlanClosed);
  }, [roomPlanClosed]);

  const getSeatCode = () => {
    return (number || 0) + 1;
  }

  const removeSeat = () => {
    if (isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)) {
      return;
    }

    if (isStaff) {
      parentRemoveSeat(index);
    } else {
      setGuestName('');
      setGuestAge(GuestAgeEnum.ADULT);
      setGuestRestrictions([]);
      parentUpdateSeat('UPDATE_SEAT', index, {
        number,
        couple: isCouple,
        frontCouple,
        guestName: '',
        guestAge: GuestAgeEnum.ADULT,
        foodRestrictions: []
      });
    }
  }

  const handleNameChange = (event) => {
    if (isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)) {
      return;
    }

    selectedMainDish(guestRestrictions);

    setGuestName(event.target.value);
    parentUpdateSeat('UPDATE_SEAT', index, {
      number,
      couple: isCouple,
      frontCouple,
      guestName: event.target.value,
      guestAge,
      foodRestrictions: guestRestrictions
    });
  };

  const handleAgeChange = (value) => {
    if (isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)) {
      return;
    }

    const originalGuestAge = guestAge;
    const restrictionsToUpdate = setRestrictionsBasedOnAge(originalGuestAge, value, guestRestrictions, foodRestrictions);
    setGuestAge(value);
    setGuestRestrictions(restrictionsToUpdate);

    parentUpdateSeat('UPDATE_SEAT', index, {
      number,
      couple: isCouple,
      frontCouple,
      guestName,
      guestAge: value,
      foodRestrictions: restrictionsToUpdate
    });
  }

  const handleChangeRestriction = (values) => {
    if (isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)) {
      return;
    }

    // if (wedding?.upgrade?.tag === 'TREND') {
    //   const isValid = hasRequiredRestriction(values);
    //   setRestrictionError(!isValid);
    // }

    setGuestRestrictions(values);
    parentUpdateSeat('UPDATE_SEAT', index, {
      number,
      couple: isCouple,
      frontCouple,
      guestName,
      guestAge,
      foodRestrictions: values
    });
  }

  const showAlert = () => {
    if (isValidated) {
      return Alerts.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('ROOM_PLAN_APPROVED')
      });
    }
    else if (isRoomPlanClosed) {
      return Alerts.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        // text: translate('BLOCKED_ANSWER_TEXT')
      });
    }
  }

  const reorderFoodRestrictions = (foodRestrictions) => {
    const upgradeSelections = foodRestrictions.filter(f => f?.upgradeSelection);
    const nonUpgradeSelections = foodRestrictions.filter(f => !f?.upgradeSelection);

    if (wedding?.upgrade?.tag === 'TREND') {
      return [...upgradeSelections, ...nonUpgradeSelections];
    }

    return nonUpgradeSelections;
  }

  const blockBothMainDishesForFoodRestrictions = () => {
    if (wedding?.upgrade?.tag === 'TREND') {
      if (
        (dishes?.meatAnswer && !dishes?.fishAnswer) ||
        (!dishes?.meatAnswer && dishes?.fishAnswer) ||
        (!dishes?.meatAnswer && !dishes?.fishAnswer)
      ) {
        return true;
      }
    }

    return false;
  }

  const selectedMainDish = (restrictions) => {
    if (wedding?.upgrade?.tag === 'TREND') {
      const MEAT_RESTRICTION_ID = '67c03cceb272a8dfe4179380';
      const FISH_RESTRICTION_ID = '67c03d27b272a8dfe4179381';

      if (dishes?.meatAnswer && !dishes?.fishAnswer) {
        const alreadyMeat = restrictions.find(r => r === MEAT_RESTRICTION_ID);
        if (!alreadyMeat) {
          restrictions.push(MEAT_RESTRICTION_ID);
          setGuestRestrictions(restrictions);
        }
      } else if (!dishes?.meatAnswer && dishes?.fishAnswer) {
        const alreadyFish = restrictions.find(r => r === FISH_RESTRICTION_ID);
        if (!alreadyFish) {
          restrictions.push(FISH_RESTRICTION_ID);
          setGuestRestrictions(restrictions);
        }
      }
    }

    return restrictions;
  }

  const hasRequiredRestriction = (restrictions) => {
    return REQUIRED_RESTRICTIONS.some(req => restrictions?.includes(req));
  };

  const restrictionsColors = (restrictions) => {
    const MEAT_COLOR = '#d6f5d6';
    const FISH_COLOR = '#cce0ff';
    const VEGETARIAN_COLOR = '#ffebcc';
    const VEGAN_COLOR = '#ecd8c6';
    const CHILDMENU_COLOR = '#ffccf2';

    let colors = [];
    if (wedding?.upgrade?.tag === 'TREND') {
      for (const r1 of REQUIRED_RESTRICTIONS) {
        const mainDishSelected = restrictions.find(r2 => r2 === r1);
        if (mainDishSelected) {
          switch (r1) {
            case '67c03cceb272a8dfe4179380':
              colors.push(MEAT_COLOR);
              break;
            case '67c03d27b272a8dfe4179381':
              colors.push(FISH_COLOR);
              break;
            case '6576f9f0e5c2e224a81aeaa1':
              colors.push(VEGETARIAN_COLOR);
              break;
            case '6576f9f0e5c2e224a81aeaa2':
              colors.push(VEGAN_COLOR);
              break;
            case '6576f9f1e5c2e224a81aeab6':
              colors.push(CHILDMENU_COLOR);
              break;
          }
        }
      }
    }

    return colors;
  }

  return (
    <SeatRow key={index} disabled={disabled} hasError={restrictionError}>
      <TableSeatCode seat={true} couple={isCouple}>
        {getSeatCode() || 'X'}
      </TableSeatCode>

      <SeatName>
        <MaterialInput
          type="text"
          translate="no"
          placeholder={translate('NAME')}
          noLabel={true}
          readOnly={isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)}
          value={guestName}
          onChange={handleNameChange}
          onClick={() => showAlert()} />
      </SeatName>
      <SeatAge staff={isStaff}>
        <SelectAgeInput
          placeholder={translate('AGE')}
          meta={{ invalid: false, submitFailed: false }}
          data={AgeTypes}
          readOnly={isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)}
          translatable={true}
          translate={translate}
          input={{
            value: guestAge,
            onChange: handleAgeChange,
            onFocus: () => showAlert()
          }} />
      </SeatAge>
      <SeatFoodRestrict staff={isStaff}>
        <SelectRestrictionInput
          placeholder={translate('ALLERGIAS')}
          meta={{ invalid: false, submitFailed: false }}
          readOnly={isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)}
          blockMainDishes={blockBothMainDishesForFoodRestrictions()}
          data={reorderFoodRestrictions(foodRestrictions)}
          restrictionsColors={restrictionsColors(guestRestrictions)}
          input={{
            value: guestRestrictions,
            onChange: handleChangeRestriction,
            onFocus: () => showAlert()
          }}
        />
      </SeatFoodRestrict>
      <DeleteSeatButton
        readOnly={isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)}
        onClick={removeSeat}>
        <Icon type="delete" />
      </DeleteSeatButton>
    </SeatRow>
  );
}
export default GuestSeatComponent;